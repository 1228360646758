.travel-hero-msg-box {
    /* position: absolute; */
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* top: 80px; */
    padding-top: 80px;

    object-fit: contain;
    background-image: url('../../images/travel/travel.jpg');

    background-repeat: no-repeat;
    background-size: cover;

    color: black;

}

.travel-hero-desciption {
    max-width: 700px;

    margin: 40px;
    margin-bottom: 60px;

    font-size: 22px;
    font-weight: 500;
    color: rgb(255, 255, 255);

    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    /* color: black; */
    text-align: center;
}

.travel-hero-title {
    font-size: 38px;
    font-weight: 800;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: black;
    margin-bottom: 10px;
    text-align: center;
}

.attraction-routcard-box {
    /* background-color: rgb(255, 235, 235); */
    width: 90%;
    margin: 20px;
    display: flex;
    justify-content: space-between;
}

.attraction-routcard-ul-1,
.attraction-routcard-ul-2 {
    width: 50%;
}

.attraction-park-box {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.attraction-park-box h3 {
    text-transform: capitalize;
}

.attraction-routcard-title {
    font-weight: 900;
    margin-bottom: 5px;
}

.attraction-routcard-list-ul {
    margin: 0 20px;
}

.attraction-park-list {
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
}

.attraction-park-discription {
    white-space: pre-wrap;
    text-align: justify;
    margin: 5px 10px;
    margin-bottom: 25px;
}

.attraction-park-box h3 {
    text-transform: capitalize;
}

/* Toure Package */
.tour-package-box {

    text-align: center;
    justify-items: start;
}

.tour-package-card {
    display: inline-block;
    margin: 20px;
    text-align: start;
    border: 1px solid rgb(211, 211, 211);
}

.tour-package-card-dec-box {
    padding: 10px;
}

.tour-package-card-ul {
    margin: 5px;
    margin-left: 20px;
}

.tour-package-card-day {
    display: inline-block;
    margin: 20px;
    text-align: start;
    border: 1px solid rgb(211, 211, 211);
    padding: 20px;
}

.tour-packages-main{
    max-width: 1024px;
    background-color: white;
    border: 1px solid rgb(211, 211, 211);
    margin-bottom: 30px;
}

.tour-packages-box{
    border-left: 4px solid rgb(80, 80, 80) ;
    margin: 30px 30px 30px 40px;
    padding-left: 30px;
    position: relative;
}

.tour-packages-box-dec{
    padding-top: 20px;
    /* padding-bottom: 30px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.tour-packages-box-round{
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #206A5D;
    top: 0;
    left: -15px;
}

.tour-packages-box-dec-text{
    font-weight: 400;
    text-align: justify;
}

.tour-packages-main hr{
    margin-left: 40px;
    margin-right: 30px;
}

.tour-card-box{
    margin-bottom: 30px;
}

.tour-card-decs{
    margin: 10px 20px;
    text-align: justify;
}

.tour-card-list{
    margin: 0 20px;

}

.tour-card-list-item-box{
    display: inline-block;
}

.tour-card-list-item{
    background-color: rgb(235, 255, 250);
    height: 26px;
    border-radius: 15px;
    padding: 0 20px;
    border: 1px solid rgb(14, 176, 38);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    
}

.tour-card-list-item p{
    width: fit-content;
}

@media only screen and (max-width: 600px) {

    .tour-packages-box{
        border-left: 2px solid rgb(80, 80, 80) ;
        margin: 10px;
        padding-left: 15px;
    }


    .tour-packages-box-round{
        position: absolute;
        width: 16px;
        height: 16px;
        left: -9px;
    }
    

    .tour-card-decs{
        font-size: 14px;
    }
    
    .tour-card-list-item p{
        font-size: 14px;
    }

    .tour-packages-box h2{
        font-size:20px;
    }

    .tour-card-list-item{
        height: 22px;
        border-radius: 11px;
        padding: 0 10px;
    }
    .tour-card-box h3{
        font-size: 16px;
    }

    .tour-card-decs{
        margin: 10px 10px;
    }
    
    .tour-card-list{
        margin: 0 10px;
    }

    .tour-packages-box-dec-text{
        font-size: 14px;
    }

    .attraction-park-discription {
        margin: 5px 0;
    }
    
}

@media only screen and (max-width: 898px) {
    .attraction-routcard-box {
        flex-direction: column;
    }

    .attraction-routcard-ul-1,
    .attraction-routcard-ul-2 {
        width: 100%;
    }

    .attraction-routcard-ul-2 {
        margin-top: 20px;
    }
}