.packages-section{
    /* background-color: red; */

    z-index: 100;

    position: absolute;
    width: 100%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding: 100px 0px;
    top: 580px;
}

.packages-box{
    /* background-color: aquamarine; */
    width: 100%;
    max-width: 1024px;

    display: flex;
    align-items: center;
    justify-content: right;
    
    gap: 50px;

}

.package-1{
    /* background-color: white; */
    /* background-image: url('../../images//packages//travel.jpg'); */
    background-position: center;
    background-size: cover; 
    background-color: rgb(255, 255, 255);
    width: 300px;
    aspect-ratio: 5/2;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    border-radius: 50px;

    transition: 0.5s;
    text-align: center;
    
}

.package-1:hover{
    transform: scale(1.03);
}

.package{

    /* background-image: url('../../images//packages//offere.png'); */
    background-position: center;
    background-size: cover; 

    background-color: #206A5D;
    color: white;
    width: 300px;
    aspect-ratio: 5/2;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    display: flex;
    align-items: center;
    justify-content: center;


    position: relative;
    border-radius: 50px;

    transition: 0.5s;
    
    text-align: center;
    
}
.package:hover{
    transform: scale(1.03);
}


.img{
    position: absolute;
    top: -50px;
    width: 100px;
}