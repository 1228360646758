.toure-section {
    width: 100%;
    min-height: 500px;
    background-color: rgba(0, 0, 0, 0.651);
    /* margin-top: 0; */
    /* margin-bottom: 0; */
    display: flex;
    justify-content: center;
    align-items: center;

    /* max-width: 700px; */
    padding: 20px;

    color: white;
}

.toure {
    /* margin-top: 50px; */
    /* padding-bottom: 20px; */
    width: 100%;
    /* height: 100vh; */
    max-width: 700px;
    /* height: 500px; */
    font-size: 42px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.toure-img {
    width: 400px;

}



.toure-title {
    text-align: center;
}

.toure-title-title {
    font-size: 48px;
    font-weight: 700;
}

.toure-title-discription {
    font-size: 36px;
    margin-bottom: 20px;
}

.toure-discription {
    font-size: 18px;
    margin-bottom: 20pxs;
}

.travel-img-box {
    position: relative;
    /* background-color: red; */
}

.toure-img {
    position: relative;
    z-index: 50;
    width: 550px;
}

.toure-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 51;
    width: 550px;
}



/* ================================== */

.toure-parallax {
    /* The image used */
    background-image: url("../../images//travel//travel.jpg");
    /* background-blend-mode: multiply; */
    /* Set a specific height */
    min-height: 500px;
    width: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}