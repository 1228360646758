.total-cartcard{
    padding: 10px;
    border: 1px rgb(187, 187, 187) solid;
}

.total-cart-Details-box{
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
}

.total-cart-title{
    font-size: 18px;
}

.total-cart-description{
    font-size: 12px;
    color: rgb(150, 150, 150);
}

.total-cart-price{
    font-size: 20px;
    font-weight: 700;
}