.room-g-card{
    background-color: white;
    width: 320px;
    aspect-ratio: 5/4;
    transition: transform .2s;
    border: 1px solid rgb(212, 212, 212);
    /* margin: 10px; */
}

.room-g-card:hover{
    transform: scale(1.01);
}

.group-img{
    width: 100%;
}

.roomgroup-details-box{
    padding: 0px 10px 10px 10px;
    /* height: 100%; */
    max-height: 250px;


    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    

    /* background-color: red; */
}

.roomgroup-header{
    font-size: 24px;
    font-weight: 100;
    font-family: 'Libre Baskerville', serif;
    /* font-style: italic; */
}

.roomgroup-price-box{
    float: right;
    /* background-color: white; */
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.roomgroup-price{
    font-size: 20px;
}

.roomgroup-price>span{
    font-size: 32px;
    font-weight: 600;
}