.offers-topup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 105;
    background-color: rgba(0, 0, 0, 0.271);

    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

.offers-topup-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 90%;
}

.offers-topup-close {
    text-align: right;
    position: absolute;
    top: 0;
    width: 100%;
}

.offers-topup-items {
    /* width: 700px; */
    /* height: 400px; */
    /* width: 100%; */
    /* height: 90%; */
    /* display: block; */
    /* background-color: green; */
    text-align: center;
    /* margin: auto ; */
}



.mySlides {
    display: none;
}

.imgshow {
    vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
    max-width: 800px;
    position: relative;
}


/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.slidershow-dot {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: center
}


.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;


    margin-top: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active1 {
    background-color: #717171;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: .5s;
}

@keyframes fade {

    /* from {opacity: .4} 
    to {opacity: 1} */

}