.attraction-page {
    /* background-color: #f5f5f0; */

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    /* padding: 0 10px 10px 10px; */

    position: relative;
}

.attraction-hero-img {
    /* height: 50vh; */
    width: 100%;
    position: relative;
    object-fit: contain;
    background-image: url('../../images/hero/Anuradhapura.jpg');
}

.attraction-hero-section {
    position: relative;
    /* background-color: red; */
    height: fit-content;
    width: 100%;
}

.attraction-hero-msg-box {
    /* position: absolute; */
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* top: 80px; */
    padding-top: 80px;

    object-fit: contain;
    background-image: url('../../images/hero/Anuradhapura.jpg');

}

.attraction-hero-title {
    font-size: 38px;
    font-weight: 800;
    color: white;
}

.attraction-hero-desciption {
    max-width: 800px;

    margin: 30px;

    font-size: 22px;
    font-weight: 100;
    color: rgb(223, 223, 223);
    text-align: center;
    
    margin-bottom: 60px;
}

.attraction-history-box-section{
    /* position: absolute; */

    /* left: 0; */
    /* right: 0; */
    /* bottom: -180px; */
    /* background-color: aqua; */
    
    margin: 10px;
    margin-top: -20px;


    display: flex;
    align-items: center;
    justify-content: center;
}

.attaction-history-box {
    /* margin-top: 50px; */

    /* top: 0; */
    /* margin: auto; */
    
 

    background-color: white;
    z-index: 200;
    padding: 40px;
    max-width: 900px;
    /* border-radius: 15px; */
    /* margin-bottom: 100px; */

    border: 1px rgb(201, 201, 201) solid;
}

.attraction-history-title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
}

.attraction-history-desciption {

    font-weight: 400;
    font-size: 22px;
    text-align: justify;
}


.attraction-body {
    margin-top: 50px;

}

.attraction-body-title-box{
    padding: 50px;
    margin-top: 20px;
    /* background-color: red; */
    text-align: center;

}
.attraction-body-title{
    
    font-size: 32px;
    font-weight: 800;
    color: #206A5D;
}


.attraction-card-box{
    max-width: 1000px;
    padding: 20px;

    border: 1px rgb(209, 209, 209) solid;
    background-color: white;
    border-radius: 5px;

    display: flex;
    /* flex-direction: column; */
    gap: 20px;
    margin-bottom: 50px;
    transition: transform .5s;
}

.attraction-card-box:hover{
    transform: scale(1.02);
}

.attraction-card-img-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.attraction-card-img-box>img{
    border-radius: 10px;
}

.attraction-card-msg-box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.attraction-card-title{
    font-weight: 800;
    margin-bottom: 20px;
    font-size: 24px;
}

.attraction-card-description{
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    white-space: pre-wrap;
}

.attraction-card-description>p{
    font-family: 'Della Respira', serif;
}

.attraction-bottom-card-description{
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    white-space: pre-wrap;
}

.attraction-bottom-card-box{
    max-width: 1000px;
    padding: 20px;
    
    border: 1px rgb(209, 209, 209) solid;
    background-color: white;
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
    transition: transform .5s;
    box-shadow: none;
}
.attraction-bottom-card-box:hover{
    transform: scale(1.005);
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}


@media only screen and (max-width: 600px) {
    .attraction-hero-desciption{
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .attaction-history-box {
        padding: 35px;
    }
    .attraction-hero-title {
        font-size: 20px;
    }

    .attraction-hero-desciption{
        font-size: 16px;
    }

    .attraction-history-title{
        font-size: 20px;
    }
    .attraction-history-desciption{        
        font-size: 16px;
    }

    .attraction-body{
        margin-top: 0;
    }

    .attraction-body-title{
        font-size: 24px;
    }

    .attraction-card-box{
        padding: 0;
    }
    
  }



  /* ------------------------ Mobile */


  .attraction-card-box-xs{
    /* max-width: 1000px; */
    /* padding: 20px; */

    /* width: 100%; */
    /* padding: 5px; */

    border: 1px rgb(209, 209, 209) solid;
    background-color: white;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 20px; */
    margin-bottom: 50px;
    transition: transform .5s;
}

.attraction-card-box-xs:hover{
    transform: scale(1.02);
}

.attraction-card-img-box-xs>img{
    margin-top: 20px;
    width: 100%;
}

.attraction-card-msg-box-xs{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.attraction-card-title-xs{
    font-weight: 800;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
}

.attraction-card-description-xs{
    /* font-weight: 400; */
    /* font-size: 14px; */
    white-space: pre-wrap;
    text-align: justify;
    
    font-family: 'Comic Neue', cursive !important;
}