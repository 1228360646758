.checkout-page {
    background-color: #f5f5f0;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 0 10px 10px 10px;


    /* background-color: red; */

}

.contact-checkout-box {
    /* width: 100%; */
    /* max-width: 1024px; */

    /* background-color: red; */

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 50px 0;
}

.checkout-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    max-width: 1150px !important;
    /* padding: 10px; */
    margin-top: 20px;
    /* background-color: rgb(248, 248, 248); */
}

.checkout-cart {
    flex: 3;

    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;

    padding: 10px;
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(214, 214, 214);
}

.checkout-cart-form-list{
    flex: 3;
    width: 100%;
}

.checkout-cart-form {
   

    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;

    /* padding: 10px; */
    background-color: white;
    border: 1px solid rgb(214, 214, 214);
}



.checkout-out {
    flex: 2;

    padding: 10px;
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(214, 214, 214);
}


.checkout-out-box {
    background-color: white;
    /* padding: 5px; */
    margin-top: 10px;

    border: 1px solid rgb(230, 230, 230);
}

.checkout-out-box-btn {
    display: flex;
    justify-content: end;
    padding: 0 16px 16px 0;
}

.contact-form-checkout {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
}


.co-cart-box {
    background-color: white;
    /* padding: 16px; */
    border: 1px solid rgb(230, 230, 230);
    position: relative;
    min-height: 100px;
    width: 100%;
}

.co-cart-box-dis {
    background-color: rgb(218, 231, 216);
    /* padding: 16px; */
    border: 1px solid rgb(230, 230, 230);
    position: relative;
    min-height: 90px;
    width: 100%;
    /* height: auto; */
}

.co-cart-box-total {
    background-color: #fff;
    /* background-color: #206A5D; */
    padding: 10px;
    /* color: white; */
    border: 1px solid rgb(230, 230, 230);
    /* position: relative; */
    /* min-height: 90px; */
    /* width: 100%; */
    margin-bottom: 20px;
    /* height: auto; */
}

/* .co-cart-total-box {

} */

.co-cart-total-box-r {
    display: flex;
    gap: 20px;
}

.co-cart-total-box-price {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}

.co-cart-total-offer-box {
    display: flex;
    justify-content: end;
    gap: 20px;
    /* margin-bottom: 10px; */
}

.co-cart-offer-title {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    /* background-color: red; */
}

.co-cart-offer-value {    
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
}

.co-cart-total-final{
    margin-top: 20px;
    display: flex;
    gap: 30px;
    justify-content: end;
}

.co-cart-price-box {
    position: absolute;
    right: 16px;
    bottom: 16px;

    /* background-color: red; */

    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.co-cart-price-box-text {
    /* float: right; */
    color: rgb(0, 83, 0);

}


.checkout-book-cpt{
    border: 1px solid rgb(218, 218, 218);
    border-bottom: 15px solid rgb(0, 83, 0);
    background-color: rgb(252, 252, 252);
    /* margin-top: 40px; */
    padding: 30px;
    border-radius: 20px;

    border-radius: 10px 10px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

}

.total-currency-box{

    font-family: 'Courier New', Courier, monospace;
    
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    font-size: 14px;
    
}

.cardlist-box{
    border: 1px solid rgb(214, 214, 214);
    margin-bottom: 10px;
    background-color: rgb(248, 248, 248);
}

.cardlist-card{
    border: 1px solid rgb(230, 230, 230);
    margin: 10px 10px 0 10px;
    padding: 10px;
    background-color: white;
}

.cardlist-card-input{

    border: 1px solid rgb(230, 230, 230);
    padding: 0 5px;
}

.cardlist-card-package{
    font-size: small;
}

.cardlist-card-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: end; */
}

@media only screen and (max-width: 430px) {
    .cardlist-card-item{
        flex-direction: column;
        /* justify-content: end; */
        align-items:  space-between;
    }

    .cardlist-card-item-price{
        margin-top: 10px;
    }
}