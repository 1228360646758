.heromessage-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* height: 410px; */
    justify-content: center;
    align-items: start;
    /* background-color: rgba(0, 0, 0, 0.152); */
    padding: 10px;
}

.hromessage-title{
    font-size: 78px;
    /* color: rgb(240, 164, 1); */
    color: #206A5D;
    /* color: rgb(164, 134, 0); */
    /* color: #206A5D; */
    font-weight: 600;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */

    
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
}

.hromessage-discription{
    font-size: 18px;
    color: white;
    font-weight: 200;
}