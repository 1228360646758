.footer{

    /* margin-top: 20px; */

    width: 100%;
    
    height: 100px;
    background-color: #206A5D;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link-icons{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}