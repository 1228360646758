.contact-page{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 10px 10px;

}
.contact-box{
    
    width: 100%;
    height:auto;
    max-width: 1024px;

    /* background-color: red; */

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 50px 0;
}


.contact-details-inputs-box{
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
}

.contact-details-box{
    flex: 1;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact-details-box>p {
    font-size: 18px;
    font-weight: 100;
}

.contact-details-box-ne>p{
    font-size: 18px;
    font-weight: 100;
}

.contact-inputs-box{
    flex: 1;
    padding: 10px;
}

.contact-form{
    display: flex;
    flex-direction: column;

    gap: 30px;
}

.contact-d-description-find{
    font-size: small;
}

.link-icons-find{
    margin-left: 10px;
    display: flex;
    gap: 15px;
}

@media only screen and (max-width: 600px) {
    .contact-box{
        padding: 0;
    }

    .contact-details-inputs-box{
        margin-top: 20px;
        padding: 0;
    }

    .contact-details-box{
        margin-bottom: 20px;
    }

    .contact-details-box>p{
        font-size: 14px;
    }

    .contact-details-box-ne>p{
        font-size: 14px;
    }
}