.linearavailability-box{
    position: sticky;
    top: 0px;
      
    width: 100%;
    max-width: 1024px;
    

    
    z-index: 20;

    /* background-color: #f5f5f0; */
    background-color: white;
}

.linearavailability{
    background-color: white;
        
    /* padding: 10px 0px; */
    margin-top: 10px;
    margin-bottom: 10px;

    border: 1px solid #91b1ab;



    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}

.linearavailability-xs{
    background-color: white;
        
    /* padding: 10px 0px; */
    margin-top: 10px;
    margin-bottom: 10px;

    border: 1px solid #91b1ab;



    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
}

.btn{
    width: 100%;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    /* padding: 2px 12px 10px 12px !important; */
    font-size: '16px' !important;
}

.l-vertical-line{
    border-left: 1px solid #91b1ab;
    height: auto;
}

.destopDataPicker-btn .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-style: none !important;
}

.l-check-title{
    margin: 5px 0 0 10px;
    font-size: 12px;
}

.l-check-box{
    width: 100%;
}

.css-igs3ac{
    border: none;
}

.css-igs3ac{
    border-style: '';
}