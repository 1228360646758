.room-box {
    width: 100%;
    background-color: white;
    border: 1px rgb(209, 209, 209) solid;
}

.image-box {
    width: 100%;
}

.hero-slider-wrapper{
    aspect-ratio: 16/9 ;
}

.room-details-box {
    padding: 10px;
}


.room-title {
    font-size: 24px;
    font-weight: 700;
}

.room-description{
    font-weight: 400;
    margin: 10px;
}

.index-module_Button__hkx4Z svg{
    width: 40px !important;
    height: 40px !important;
    /* opacity: 0.5 !important; */
}
.index-module_Button__hkx4Z svg:hover{
    opacity: 1 !important;
}

.room-selectable {
    /* background-color: #f5f5f5; */
    background-color: white;
    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.room-facilities-box {
    padding: 10px 20px;


}

.selected-price {
    font-size: 18px;
    font-weight: 600;
}

.selected-price-details {
    font-size: 14px;
    color: rgb(146, 146, 146);
}

.index-module_Active__5-5Mj{
    z-index: 0;
}