.offerspage{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 104;
    background-color: rgba(0, 0, 0, 0.611);

    display: flex;
    justify-content: center;
    align-items: center;



}

.offerspage-topup-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    max-width: 1024px;
}

.offerspage-topup-close{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    background-color: #206A5D;
    color: white;
}

.offerspage-topup-items{
    /* position: relative; */
    
    width: 100%;
    height: 100%;
    
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */

    overflow-y: auto;
    overflow-x: hidden;
    /* width: 800px; */
    /* height: 500px; */
    background-color: rgba(255, 255, 255, 0);
}


.offerspage-btn-box{
    position: fixed;
    z-index: 102;
    width: 100px;
    height: 40px;
    background-color: white;
    border-radius: 20px 0 0 20px;
    border: 1px solid black;
    border-right: none;

    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 15px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    bottom: 50px;
    right: 0;

    font-weight: 800;
    transition: 0.2s;
}

.offerspage-btn-box:hover{
    width: 105px;
    transform: scale(1.02);
    background-color: #206A5D;
}

.offerspage-btn-box:active{
    background-color: #47a190;
}


@media only screen and (max-width: 750px) {
    .offerspage-topup-items{
        width: 100%;
        height: 100%;
    }

    .offerspage-topup-close{
        width: 100%;
    }
}
