.home-page {
  background-color: white;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-x: hidden;
}

.hero-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 150px;
  min-height: 400px;
}

.hero-section-img {
  position: relative;
  background-color: black;
}

.main-bg-img {
  position: absolute;
  bottom: -15px;
  z-index: 5;
  width: 100%;
}

.main-bg-video {
  height: 100%;
  /* height: 100vh; */
  /* filter: brightness(90%);  */
  z-index: 3;

}

.main-bg-hero-img {
  height: 100%;
  /* object-fit: cover; */
  /* height: 100vh; */
  /* filter: brightness(90%);  */
  z-index: 2;

}

.main-bg-black {
  background-color: rgba(0, 0, 0, 0.445);

  position: absolute;

  z-index: 4;
  top: 0;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
}

.main-screen {
  position: absolute;
  margin-top: 150px;
  max-width: 1024px;
  width: 100%;

  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

/* -*----------------------- */

.loadinghome-title {
  font-weight: 800;
}

.loadinghome {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;

  z-index: 110;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.loading-contaner {
  width: 150px;
  height: 150px;
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }

  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }

  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}

.services-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 400px) {
  .services-title {
    margin-top: 80px;
  }
}