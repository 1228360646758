.testimonials-box{
    background-color: white;

    width: 100%;
    /* height: 70vh; */
    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 80px 20px;

    margin-bottom: 10px;
}

.testimonials-title-box{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonoals-msg-box{
    /* background-color: red; */
    width: 100%;
    max-width: 620px;
    margin-top: 20px;
    /* max-height: 500px; */
/* 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
}
