.room-groups {
    width: 100%;
    /* height:70vh; */
    /* background-color: rgb(232, 255, 234); */
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 80px 20px;
    padding-top: 20px;
    overflow-x: auto;

}

.room-g-box {
    width: 100%;
    max-width: 1024px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 20px;

    
}