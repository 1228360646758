.cart-card-box{

    /* border: 1px rgb(187, 187, 187) solid; */
    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 5px;
    /* background-color: red; */

    height: 80px;
}


.cart-details{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 5px;
}

.cart-room-package-title{
    
    font-family: 'Comic Neue', cursive !important;
    font-weight: 600;
    font-size: 14px;
}

.cart-room-title{
    
    font-family: 'Comic Neue', cursive !important;
    font-size: 12px;
    color: rgb(150, 150, 150);
}

.cart-control{
    /* background-color: aqua; */
    height: 100%;
    min-width: 110px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
}
.cart-control-title{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
}
.cart-price-one{
    font-size: 11px;
    color: rgb(150, 150, 150);
}