.avaiilablity{
    /* top: 140px;
    left: 100px;
    position: absolute; */

    background-color: rgb(255, 255, 255);
    /* background-color: #F1F1E8; */
    min-width: 291px;
    /* width: 22.667em; */
    /* max-width:352px; */

    


    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    z-index: 4;

    
}

.check-input-title{
    font-size: 16px;
}

.availability-title{
    font-size: 28px;
    font-weight: 400;
}

.check-input-box{
    /* background-color: rgb(172, 172, 172); */
    width: 100%;

    position: relative;

}

.check-input-field{
    
    width: 100%;
    


}

.check-btn{
    width: 100%;
}

.inputs-a-c{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    /* background-color: blueviolet; */

}

.input-input-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background-color: aquamarine; */
}

.input-box{
    width: 40px;
    height: 40px;

    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 20px;

}

.inputs-titles{
    font-size: 22px;
}

.calander-box{
    position: absolute;

    z-index: 5;

    display: none;
}

.calander-box.show{
    display: block;
}

.rdrMonth{
    max-width: 22.667em;
    
}

.input-box-title{
    position: relative;
}

.input-children-age-box{
    position: absolute;
    top: 65px;
    right: 0;
    z-index: 100;
    background-color: blue;
    padding: 20px;
}