

.selectablepackage{
    padding: 8px 14px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    
}

.selectable-details-box{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}


.selectable-title{
    
    font-family: 'Comic Neue', cursive !important;
    font-size: 18px;
    font-weight: 500;
}

.selectable-details-item{
    
    font-family: 'Comic Neue', cursive !important;
    font-size: 14px;
    color: rgb(146, 146, 146);
}


.selectable-button-box{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.selectable-price{
    
    font-family: 'Comic Neue', cursive !important;
    font-size: 20px;
    font-weight: 700;
}

.selectable-price-details{
    
    
    font-family: 'Comic Neue', cursive !important;
    font-size: 12px;
    margin-bottom: 5px;
}