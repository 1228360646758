.testimonoal-box{
    
    width: 100%;
    /* max-width: 620px; */

    /* position: relative; */

    background-color: white;
}

.testimonoals-msg{

    /* position: absolute; */

    /* margin-right: 40px; */


    display: flex;

    
}

.testimonoals-msg-text{
   
    border: 1px solid rgb(224, 224, 224);
}

.testimonoals-msg-text>p{
    text-align: justify;
    font-size: 18px;
    margin: 30px;
    
}

.testimonoals-msg-expand-box{
    background-color: rgb(224, 224, 224);
    margin-top: 40px;
    width: 16px;
    height: 100hv;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.testimonoals-msg-auther{
    
    padding: 10px 20px;
    background-color: rgb(224, 224, 224);
    margin-left: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.testimonoals-msg-auther>p{
    font-size: 18px;
    font-weight: 600;
}