.gallery-page {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-box {
    width: 100%;
    max-width: 1024px;
    margin: 10px;
    margin-top: 50px;

    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.gallery-img-box {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.gallery-img-box-1 {
    display: block;
}

.gallery-img-box-2 {
    display: none;
}

.gallery-img-box-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}


@media only screen and (max-width: 900px) {
    .gallery-img-box {
        flex-direction: column;
    }

    .gallery-img-box-div {    
        margin-top: 20px;
        gap: 100px;
    }

    .gallery-img-box-1 {
        display: none;
    }

    .gallery-img-box-2 {        
        display: block;
    }

 

}