.checkout-payment-page {
    width: 100%;
}

.hco-embedded {

    background-color: white;
}

.payment-info {
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: white;

    display: flex;
    gap: 10px;
    max-width: 700px;
    min-width: 600px;
}

.payment-info-c {
    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 80px;
}

.payment-room {
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 10px;
    /* margin-bottom: 10px; */
    max-width: 700px;
    /* min-width: 600px; */
}

.payment-info-cc {
    display: flex;
    flex-direction: column;
    min-width: 70px;
    /* gap: 2px; */
}

.payment-room-box {
    display: flex;
    gap: 10px;
}

.payment-page-book-btn{
    display: flex;
    justify-content: end;
}

@media only screen and (max-width: 600px) {
    .payment-info {
        min-width: auto;
    }

    .payment-info-c {
        min-width: 70px;
    }
    .payment-info-cc {
        min-width: 50px;
    }
}