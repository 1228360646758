.service-loc-container{
    width: 100%;

    /* background-image: url("../../images//travel/Anuradhapura-bg.jpg"); */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    min-height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;


}

.service-loc-container-box{
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.651); */
    /* display: flex;
    justify-content: center;
    align-items: center;
     */
    /* border-bottom: 5px solid black; */
    /* border-style: ridge; */
    /* min-height: 500px; */

    text-align: center;
}


.service-title{
    /* margin-top: 60px; */
    font-size: large;
}

.service-loc-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px 40px;
    /* margin: 50px 0px; */
    /* border: 1px solid rgb(180, 180, 180); */
    
}

.loc-contact-itemf{
    display: inline-block;
}



.location-frame{
    
    border: none;
    width: 100%;
    aspect-ratio: 5/3;
}

.loc-contact-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.loc-title{
margin-bottom: 30px;
    font-size: 32px;
    /* background-color: red; */
    text-align: center;
    color: white;
}

.loc-contact-item{
    background-color: rgba(0, 0, 0, 0.527);
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    color: white;

}

