.header{
    background-color: #206A5D;
   
    height: 70px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;

    z-index: 5;
}


.header.home{
    background-color: transparent;
    position: absolute;
}

.header-box{
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-title{
    /* font-size: 32px; */
    color: white;

}


.header-page-box{
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.page-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;

}

.page-box.active{
    border: 2px #81B214 solid;
    border-radius: 20px;
}

.page-title{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: white;
}

.page-box-lng{
    padding: 5px 10px;

    cursor: pointer;
}

.page-title-lng{
    color: #81B214;
}