* {
  margin: 0;
  padding: 0;

  /* font-family: 'Comic Neue', cursive; */
  font-family: 'Della Respira', serif !important;
  /* font-weight: 600; */
}

body {
  top: 0 !important;
}


p {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #206A5D;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
}


/* -------------------------------------- Google Translation ----------------------------- */

#google_translate_element {
  position: absolute;
  z-index: 7 !important;
  top: 75px !important;
  right: 0px !important;
  /* background-color: red; */

  color: white !important;
}

.goog-te-gadget {
  /* background-color: red; */

  color: white;
}

.goog-te-gadget span {
  color: white;
}

.goog-te-gadget-simple {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.678) !important;
  border: none !important;
  padding: 4px 8px 8px 6px !important;
  color: white !important;
  font-size: 11px !important;
}

.skiptranslate iframe {
  display: none;
}

.goog-te-gadget-icon {
  display: none;
}

/* 
.VIpgJd-ZVi9od-vH1Gmf{
    background-color: red ;
    padding: 8px;
} */
.VIpgJd-ZVi9od-xl07Ob-OEVmcd {
  box-shadow: none;
  font-size: 11px !important;
  background-color: rgba(255, 0, 0, 0.027) !important;
}

.text {
  font-size: 11px !important;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b div {
  background: rgba(96, 66, 66, 0.131) !important;
}

.dropdown {
  position: fixed;
  display: inline-block;
  z-index: 111;
  top: 75px;
  right: 0;

  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.807) !important;
  border: none !important;
  padding: 6px 12px 6px 10px !important;
  color: white !important;
  font-size: 11px !important;
  
}

.dropdown-content {
  display: none;
  /* position: absolute; */
  /* background-color: #f9f9f9;  */
  color: white;
  min-width: 70px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dropdown-content-list p {
  background-color: black;
  color: white;
  text-align: center;
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.dropdown-content-list p:hover {
  
  background-color: rgb(24, 24, 24);
}
.dropdown-content-list p:active {
  
  background-color: rgb(48, 48, 48);
}

@media only screen and (max-width: 898px) {
  #google_translate_element {
    top: 20px !important;
    right: 80px !important;
  }

  .goog-te-gadget-simple {
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.34) !important;
    border: none !important;
    padding: 2px 6px 6px 4px !important;
    color: white !important;
    font-size: 11px !important;
  }
}