.rooms-page{
    /* background-color: #f5f5f0; */

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 0 10px 10px 10px;

    position: relative;
}

.rooms-box{


    width: 100%;
    max-width: 1024px;

    /* background-color: aqua; */

    
    /* display: flex;
    flex-direction: row; */
    justify-content: space-between;
    /* align-items: start; */
    gap: 20px;

}

.rooms-section{
    width: 100%;
    max-width: 1024px;

    /* background-color: aqua; */

    
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
}

.rooms-title{
    font-size: 26px;
}

.avalability-and-cart{


    padding-top: 50px;

    position: sticky;
    /* top: 30px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
}