.cart-box{



    background-color: rgb(255, 255, 255);

    width: 100%;
    min-width: 300px;

    height: auto;
    

    /* border: 1px rgb(187, 187, 187) solid; */
    
}

.selected-policies-box{
    background-color: #f5f5f0;
    /* border-radius: 10px; */
    padding: 0px 5px;
}

.selected-policies-msg{
    font-size: 12px;
}

.selected-policies-box-border{
    height: 10px;
}